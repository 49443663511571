<template>
  <div>
    <el-col
            style="text-align: center;"
  >
    <el-form-item
            :prop="prop"
            :rules="rules"
            :error="errors"
    >
      <el-checkbox
              v-model="unnax.open_bank_integration"
              :disabled="unnax_checkbox_disabled"
              @change="setUnnaxDialogVisible(true)"
      >
        <div style="white-space: initial;">
          {{ label ? label : $t('commons.open_banking.label') }}
        </div>
      </el-checkbox>
    </el-form-item>
  </el-col>
    <el-col
            class="pz-large-margin"
    >
          <div >
            {{ $t('commons.open_banking.label_adicional') }}
          </div>
    </el-col>
    <el-col :span="24">
      <el-dialog
        class="pz-dialog-banking"
        :title="$t('commons.bank_aggregation.title_header')|capitalize({ onlyFirstLetter: true })"
        :visible.sync="show_unnax_dialog"
        width="50%"
        center
      >
        <el-row class="row justify-content-center">
          <pz-unnax-iframe
            v-if="unnax.widget_url"
            :url="unnax.widget_url"
          />
        </el-row>
      </el-dialog>
    </el-col>
  </div>
</template>

<script>
import PzUnnaxIframe from "@/commons/forms/Fields/OpenBanking/PzUnnaxIframe";
import {getBankIntegrationWidgetUrl} from "@/routes/api/views/integracion";
import {isEmpty} from "@/commons/utils/functions";

export default {
  name: "UnnaxBankAggregation",
  components: {PzUnnaxIframe},

  props: {
    errors: {type: String, default: null},
    rules: {type: Object, default: null},
    prop: {type: String, default: null},
    value: {type: Object, default: null},
    label: {type: String, default: null}
  },

  data() {
    // valida que el proceso UNNAX ha sido concluido satisfactoriamente
    let checkUnnaxComplete = (rule, value, callback) => {
      if (this.formulario1.is_unnax_completed === '') {
        return callback(new Error('El proceso UNNAX no ha concluido'));
      }
      return callback();
    };

    return {
      unnax: {
        open_bank_integration: false,
        widget_url: ''
      },
      formulario1: {
        is_unnax_completed: '',
        not_route_obtained: false,
        unnax_timestamp: Date.now()
      },
      rules_formulario1: {
        is_unnax_completed: [{validator: checkUnnaxComplete, trigger: 'blur'}]
      },
      show_unnax_dialog: false
    }
  },

  computed: {
    // deshabilita el checkbox de UNNAX cuando el proceso ha sido culminado satisfactoriamente.
    unnax_checkbox_disabled() {
      return this.formulario1.is_unnax_completed === 'completed' || this.formulario1.not_route_obtained
    }
  },

  mounted() {
    this.initialize();
    // activa la escucha de mensajes provenientes del iframe
    window.addEventListener("message", (evt) => {
      if (evt.data === 'unnax:aggregation:done') {
        this.formulario1.is_unnax_completed = 'completed';
        setTimeout(() => this.closeDialog(), 1000)
      }
      if (evt.data === 'unnax:aggregation:bank_selection_view:done') {
        this.$store.dispatch('loader/down', {trigger: this.$options.name});
      }
    });
  },

  methods: {
    // inicializa valores por defecto
    initialize() {
      this.formulario1.is_unnax_completed = '';
      this.formulario1.not_route_obtained = false;
    },
    // cierra el modal de UNNAX
    closeDialog() {
      this.setUnnaxDialogVisible(false);
    },
    // cambia la visibilidad del modal de UNNAX
    setUnnaxDialogVisible(val) {
      this.show_unnax_dialog = val;
      if (val) {
        this.getUnnaxWidgetUrl()
      }
    },
    // obtiene y establece la URL que se llama desde el iframe
    getUnnaxWidgetUrl() {
      this.$store.dispatch('loader/up', {trigger: this.$options.name})
      getBankIntegrationWidgetUrl({unnax_timestamp: this.formulario1.unnax_timestamp})
        .then(res => {
          if (res.data.valid && !isEmpty(res.data.ruta)) {
            let ruta = res.data.ruta;
            this.unnax.widget_url = ruta;
            if (ruta === true)
              this.formulario1.is_unnax_completed = 'completed';
            this.$root.$emit('set_unnax_timestamp', this.formulario1.unnax_timestamp)
          } else {
            let message = isEmpty(res.data.ruta) && res.data.valid
              ? this.$t('views.documentacion.vale.already_signed')
              : this.$t('views.documentacion.vale.not_route_obtain');
            if (!res.data.valid) {
              this.formulario1.not_route_obtained = true;
              this.unnax.open_bank_integration = false;
              this.closeDialog();
            }
            this.$store.dispatch('app/setMessage', message);
          }
        })
        .catch((error) => {
          this.$store.dispatch('app/catchErrors', {_this: this, error: error});
        })
        .finally(() => {
          this.$store.dispatch('loader/down', {trigger: this.$options.name});
        });
    }
  }
}
</script>